import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import BASE_URL from "../../utils/baseUrl";
import axiosInstance from "../../utils/axiosInstance";

export const createOrder = createAsyncThunk(
  "createOrder",
  async (orderData) => {
    try {
      const { data } = await axiosInstance.post(
        `${BASE_URL}/orders`,
        orderData
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

export const getOrdersById = createAsyncThunk(
  "getOrdersById",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.get(
        `${BASE_URL}/orders/order-by-user`
      );
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.error);
    }
  }
);

// GET: Fetch Single Order
export const getSingleOrder = createAsyncThunk(
  "getSingleOrder",
  async ({ orderId, productId }) => {
    try {
      const { data } = await axiosInstance.get(
        `${BASE_URL}/orders/order/${orderId}/${productId}`
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

// GET: Fetch All Orders
export const getAllOrders = createAsyncThunk(
  "getAllOrders",
  async ({ page = 1, limit = 10 }) => {
    try {
      const { data } = await axiosInstance.get(
        `${BASE_URL}/orders?page=${page}&limit=${limit}`
      );
      return data;
    } catch (error) {
      console.log(error);
    }
  }
);

const orderSlice = createSlice({
  name: "order",
  initialState: {
    orders: [],
    loading: false,
    msg: "",
    error: null,
    singleOrder: null,
    deliveryDetails: JSON.parse(localStorage.getItem("deliveryAddress")) || {},
    totalPages: null,
    currentPage: null,
  },
  reducers: {
    saveDeliveryDetails: (state, action) => {
      state.deliveryDetails = action.payload;
      localStorage.setItem("deliveryAddress", JSON.stringify(action.payload));
    },
    resetAll: (state, action) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createOrder.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createOrder.fulfilled, (state, action) => {
      state.loading = false;
      state.msg = "Order Placed";
    });
    builder.addCase(createOrder.rejected, (state, action) => {
      state.loading = false;
      // state.orders = action.payload
    });
    builder.addCase(getOrdersById.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getOrdersById.fulfilled, (state, action) => {
      state.loading = false;
      state.orders = action.payload;
    });
    builder.addCase(getOrdersById.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // GET: Single Order
    builder.addCase(getSingleOrder.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getSingleOrder.fulfilled, (state, action) => {
      state.loading = false;
      state.singleOrder = action.payload.data;
    });
    builder.addCase(getSingleOrder.rejected, (state, action) => {});

    // Get All Orders
    builder.addCase(getAllOrders.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAllOrders.fulfilled, (state, action) => {
      state.loading = false;
      state.orders = action.payload.orders;
      state.totalPages = action.payload.totalPages; // Total number of pages
      state.currentPage = action.payload.currentPage; // Current page
    });
    builder.addCase(getAllOrders.rejected, (state, action) => {});
  },
});

export const { saveDeliveryDetails, resetAll } = orderSlice.actions;
export default orderSlice.reducer;
