import React, { createContext, useContext, useState, useEffect } from "react";
import CustomAlert from "./CustomAlert";

const AlertContext = createContext();

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState({
    text: "",
    success: false,
    visible: false,
  });

  const [timerId, setTimerId] = useState(null);

  const showAlert = ({ text, success = false, delay = 3000 }) => {
    setAlert({ text, success, visible: true });

    // Clear any existing timer before setting a new one
    if (timerId) {
      clearTimeout(timerId);
    }

    const newTimerId = setTimeout(() => {
      setAlert((prev) => ({ ...prev, visible: false }));
    }, delay);

    setTimerId(newTimerId);
  };

  useEffect(() => {
    // Cleanup the timer when the component unmounts or alert changes
    return () => {
      if (timerId) {
        clearTimeout(timerId);
      }
    };
  }, [timerId]);

  return (
    <AlertContext.Provider value={showAlert}>
      {children}
      {alert.visible && (
        <CustomAlert text={alert.text} success={alert.success} />
      )}
    </AlertContext.Provider>
  );
};

export const useAlert = () => {
  return useContext(AlertContext);
};
