import React from "react";
import styled from "styled-components";

function Temporary() {
  return (
    <Wrapper>
      This website is currently under development. Please refrain from placing
      any orders, as they cannot be processed or delivered at this time.
    </Wrapper>
  );
}

export default Temporary;

const Wrapper = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: lightyellow;
  text-align: center;
  @media only screen and (max-width: 650px) {
    height: unset;
  }
`;
